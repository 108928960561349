import React from "react";
import "./notice.css";

import { useSelector } from "react-redux";
const NewNotice = ({ values }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="k-pdf-export notice-certificate">
            <h2 className="text-center">MUD Notice Invoice</h2>
            <div className="text-start my-4">
                <ul className="notice-main-info ">
                    <li>
                        <span>Title Company:</span>
                        <span> {user?.customer_name}</span>
                    </li>
                    <li>
                        <span>GF Number:</span>
                        <span> {values?.gf_number || ""}</span>
                    </li>
                    <li>
                        <span>Date Provided:</span>
                    </li>
                    <li>
                        <span>District:</span>
                        <span> {values?.jurisdiction_name || ""}</span>
                    </li>
                    <li>
                        <span>Fee(including tax): </span>
                        <span>$10.00</span>
                    </li>
                </ul>
            </div>
            <div className="text-center notice-logo-info">
                <span>Please remit Notice To Purchaser fee to our PO Box:</span>
                <div
                    className="notice-logo mx-auto mt-2  "
                    style={{ width: 115, height: 115 }}
                >
                    {/* <img src={logo} style={{ objectFit: "cover" }} alt="" /> */}
                </div>
                <span>Notice to Purchaser can be found on the following page.</span>
            </div>
            {/* //second page  */}
            <div className="page-break notice-second-page">
                <h2 className="text-center">NOTICE TO PURCHASER</h2>
                <div className="text-start my-4">
                    <ul className="notice-main-info fw-bold">
                        <li>
                            <span>GF Number:</span>
                            <span> {values?.gf_number || ""}</span>
                        </li>
                        <li className="mt-3">
                            <span>
                                The State of <span> {values?.state_name}</span>
                            </span>
                        </li>
                        <li>
                            <span>County of {values?.county_name}</span>
                        </li>
                    </ul>
                </div>
                <div className="purchaser-paragraph">
                    <span className="keep-together">
                        The real property , described below, that you are about to purchase
                        is located in the
                        <span className="fw-bold keep-together">
                            {" "}
                            {`${values?.jurisdiction_name || ""}`}
                        </span>
                    </span>
                    <span className="keep-together">
                        {" "}
                        (the "District ").The District has taxing authority separate from
                        any other taxing authority . As of this date , the rate of taxes
                        levied by the district on real property located in the district is{" "}
                        <span className="fw-bold keep-together">
                            {" "}
                            {values?.tax_rate || `$0`}
                        </span>
                    </span>
                    <span className="keep-together">
                        {" "}
                        on each $100 of assessed valuation . The total amount of bonds
                        ,excluding refunding bonds and any bonds or any portion of bonds
                        issued that are payable solely from revenues received or expected to
                        be received under a contract with governmental entity, approved by
                        the voters and which have been or may, at this date ,be issued by
                        the
                        <span className="fw-bold keep-together">
                            {" "}
                            {`${values?.jurisdiction_name || ""}`}{" "}
                        </span>
                    </span>
                    <span className="keep-together">
                        ,is{" "}
                        <span className="fw-bold keep-together">
                            {" "}
                            {values?.bonds_approved || `$0`}{" "}
                        </span>
                        <span className="keep-together">
                            and the aggregate initial principal amounts of all bonds issued
                            for one or more of the specified facilities of the district and
                            payable in whole or in part from property taxes is
                        </span>
                        <span className="fw-bold keep-together">
                            {" "}
                            {values?.bonds_issued || `$0`}{" "}
                        </span>
                    </span>

                    <div className="mt-2 ">
                        <span className="keep-together">
                            The District has the authority to adopt and impose a standby fee
                            on property in the District that has water , sanitary sewer , or
                            drainage facilities and services available but not connected and
                            which does not have a house , building , or other improvement
                            located there on and does not substantially utilize the utility
                            capacity available to the property . The District may exercise the
                            authority without holding election on the matter . As of this date
                            , the most recent amount of the standby fee is
                        </span>
                        <span className="fw-bold keep-together">
                            {" "}
                            {values?.stand_by_fees || `$0`}.{" "}
                        </span>
                        <span>
                            An unpaid standby fee is a personal obligation of the person that
                            owned the property at thetime of imposition and is secured by a
                            lien on the property . Any person may request a certificate from
                            the District stating the amount , if any, of unpaid standby fees
                            on a tract of property in the district .
                        </span>
                    </div>
                    <div className="mt-2">
                        <span className="keep-together">
                            By law, if a MUD , WCID , Utility or Limited District is annexed
                            into the corporate boundaries of a municipality ,it may be
                            dissolved .
                        </span>
                    </div>
                    <div className="mt-2 ">
                        <span className="keep-together">
                            The purpose of this District is to provide water , sewer ,
                            drainage , or flood control facilities and services within the
                            District through the issuance of bonds payable in whole or in part
                            from property taxes . The cost of these utility facilities is not
                            included in the purchase price of your property , and these
                            utility facilities are owned or to be owned by the District . The
                            legal description of the property which you are acquiring is as
                            follows :
                        </span>
                    </div>
                    <div className="mt-2">
                        <h3 className="fw-bold keep-together">
                            {values?.legal_description || ""}
                        </h3>
                    </div>
                    <div className="mt-2">
                        <h3 className="fw-bold keep-together">
                            PURCHASER IS ADVISED THAT THE INFORMATION SHOWN ON THIS FORM IS
                            SUBJECT TO CHANGE BY THE DISTRICT AT ANY TIME . THE DISTRICT
                            ROUTINELY ESTABLISHES TAX RATES DURING THE MONTHS OF SEPTEMBER
                            THROUGH DECEMBER OF EACHYEAR , EFFECTIVE FOR THE YEAR IN WHICH THE
                            TAX RATES ARE APPROVED BY THE DISTRICT . PURCHASER IS ADVISED TO
                            CONTACT THE DISTRICT TO DETERMINE THE STATUS OF ANY CURRENT OR
                            PROPOSED CHANGES TO THE INFORMATION SHOWN ON THIS FORM .
                        </h3>
                    </div>
                </div>
            </div>
            {/* //page second  */}
            <div className="page-break">
                <span className="keep-together">
                    The undersigned purchaser hereby acknowledges receipt of the foregoing
                    notice at our prior execution of a binding contract for the purchase
                    of real property described in such notice or at closing of purchase of
                    the real property .
                </span>

                <div className="dynamic-content my-4">
                    <span>
                        Date:<span>_______________</span>{" "}
                    </span>
                    <div className="my-2">
                        <div className="d-flex justify-content-between ">
                            {values?.buyer_1 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Purchaser (Buyer1):<span> {values?.buyer_1 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                            {values?.buyer_2 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Purchaser (Buyer2):<span> {values?.buyer_2 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-2 mt-5">
                        <div className="d-flex justify-content-between ">
                            {values?.buyer_3 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Purchaser (Buyer3):<span> {values?.buyer_3 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                            {values?.buyer_4 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Purchaser (Buyer4):<span> {values?.buyer_4 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-start mt-4">
                        <ul className="notice-main-info ">
                            <li>
                                <span>State of </span>
                                <span> {values?.state_name || ""}</span>
                            </li>
                            <li>
                                <span>County of {values?.county_name || ""}</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span>
                            This instrument was acknowledged by me on the
                            <span> ____________ </span>
                        </span>
                        <span>
                            day of<span> ________________________ </span>
                        </span>
                        <span>
                            ,20
                            <span>
                                {" "}
                                _______{" "}
                                {(values?.buyer_1 ||
                                    values?.buyer_2 ||
                                    values?.buyer_3 ||
                                    values?.buyer_4) &&
                                    "by"}
                            </span>
                        </span>{" "}
                        {values?.buyer_1 && <span> {values?.buyer_1} </span>}
                        {values?.buyer_2 && <span>, {values?.buyer_2} </span>}
                        {values?.buyer_3 && <span>, {values?.buyer_3} </span>}
                        {/* {(values?.buyer_2 || values?.buyer_3) && (
                            <span>and</span>
                        )} */}
                        {values?.buyer_4 && <span>, {values?.buyer_4} </span>}
                    </div>
                    <div className="lines mt-5">
                        <span>________________________________</span>
                        <div>
                            <span>Notary Public Signature</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-break">
                <div className="dynamic-content my-4">
                    <span>
                        Date:<span>_______________</span>{" "}
                    </span>
                    <div className="my-2">
                        <div className="d-flex justify-content-between ">
                            {values?.seller_1 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Seller (Seller1 ):<span> {values?.seller_1 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                            {values?.seller_2 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Seller (Seller2 ):<span> {values?.seller_2 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb-2 mt-5">
                        <div className="d-flex justify-content-between ">
                            {values?.seller_3 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Seller (Seller3 ):<span> {values?.seller_3 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                            {values?.seller_4 && (
                                <div className="lines">
                                    <span>
                                        ___________________________________________________
                                    </span>
                                    <div>
                                        <span>
                                            Seller (Seller4 ):<span> {values?.seller_4 || ""}</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-start mt-4">
                        <ul className="notice-main-info ">
                            <li>
                                <span>State of </span>
                                <span> {values?.state_name || ""}</span>
                            </li>
                            <li>
                                <span>County of {values?.county_name || ""}</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span>
                            This instrument was acknowledged by me on the
                            <span> ____________ </span>
                        </span>
                        <span>
                            day of<span> ________________________ </span>
                        </span>
                        <span>
                            ,20
                            <span>
                                {" "}
                                _______{" "}
                                {(values?.seller_1 ||
                                    values?.seller_2 ||
                                    values?.seller_3 ||
                                    values?.seller_4) &&
                                    "by"}
                            </span>
                        </span>{" "}
                        {values?.seller_1 && <span> {values?.seller_1} </span>}
                        {values?.seller_2 && <span>, {values?.seller_2} </span>}
                        {values?.seller_3 && <span>, {values?.seller_3} </span>}
                        {/* {(values?.seller_2 || values?.seller_3) && (
                            <span>and</span>
                        )} */}
                        {values?.seller_4 && <span>, {values?.seller_4} </span>}
                    </div>
                    <div className="lines mt-5">
                        <span>________________________________</span>
                        <div>
                            <span>Notary Public Signature</span>
                        </div>
                    </div>
                    <div className="mt-5">
                        <span>AFTER RECORDING RETURN TO:</span>
                        <div>
                            <span>
                                {user?.customer_name}
                                {user?.customer_address && ","} {user?.customer_address}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewNotice;
