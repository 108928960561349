import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { Alert, message, notification } from "antd";
// import jsPDF from "jspdf";
import "./Notice.css";
import AppSelect from "../../Components/AppSelect/AppSelect";
import AppButton from "../../Components/AppButton/AppButton";
import FormInput from "../../Components/AppInput/AppInput";
import { useSelector } from "react-redux/es/exports";
import AppTextArea from "../../Components/AppTextArea/AppText";

import { useAddnoticeMutation } from "../../Redux/Services/Notice";
import AppSpinner from "../../Components/AppSpinner/AppSpinner";
import { useGetCountyListMutation } from "../../Redux/Services/county";
import { GlobalContext } from "../../App";
import { useContext } from "react";
import { useJuriFilterTableListCountyBaseMutation, useJurisdictionBonsInfoMutation } from "../../Redux/Services/Jurisdiction";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { savePDF } from "@progress/kendo-react-pdf";
import NewNotice from "./NewNotice/NewNotice";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export default function Notice() {
  //context api
  const global = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = global.state;

  const [values, setValues] = useState({
    gf_number: "",
    state_id: "",
    county_id: "",
    jurisdiction_id: "",
    documents: "",
  });

  function clearStatus() {
    setGetError("");
  }

  const { token, user } = useSelector((state) => state.auth);

  const [getError, setGetError] = useState(false);
  const [CountyOptions, setCountyOptions] = useState([]);
  const [juriOptions, setJuriOptions] = useState([]);

  //calling from store to the statelist
  const { stateList } = useSelector((state) => state.stateList);

  const [loading, setLoading] = useState(false);
  //calling from store to the jurisdiction list
  const [mutateJuriBond, mutateJuriBondResult] = useJurisdictionBonsInfoMutation()

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  //calling rtk query hook for geting County list
  const [jurilist, jurilistResult] = useJuriFilterTableListCountyBaseMutation();

  const addnoticeApi = useAddnoticeMutation();
  const [addnotice] = addnoticeApi;

  const current = useRef(null);

  useEffect(() => {
    if (mutateJuriBondResult.isSuccess) {
      if (mutateJuriBondResult.data?.data) {
        setValues({ ...values, ...mutateJuriBondResult.data?.data })
      }
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: mutateJuriBondResult.isLoading });
  }, [mutateJuriBondResult.isLoading])

  // useEffect for downloa notice to purchaser
  useEffect(() => {
    if (addnoticeApi[1].isSuccess) {
      setLoading(false);
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addnoticeApi[1].data?.message,
      });
      savePDF(current.current, {
        keepTogether: ".keep-together",
        forcePageBreak: ".page-break",
        paperSize: "A4",
        fileName: "cert-simple.pdf",
        margin: {
          top: "0.7cm",
          left: "1.5cm",
          right: "1.5cm",
          bottom: "0.7cm",
        },
      });
    }
    if (addnoticeApi[1].error) {
      setGetError(
        addnoticeApi[1].error?.data?.message ||
        addnoticeApi[1].error.error ||
        addnoticeApi[1].error.data?.msg.sqlMessage ||
        "Somthing went wrong!"
      );
    }
    setGlobalVal({ ...globalVal, loader: addnoticeApi[1].isLoading });
  }, [addnoticeApi[1].isLoading]);

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    }
    if (countyList2[1].isError) {
      setValues({ ...values, jurisdiction_id: "", county_id: "" });
      setGetError(
        countyList2[1].error?.data?.message ||
        countyList2[1].error.error ||
        countyList2[1].error.data?.msg.sqlMessage
      );
      setCountyOptions([]);
      setJuriOptions([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: countyList2[1].isLoading });
  }, [countyList2[1].isLoading]);

  // useEffect for jurilist based on county selection
  useEffect(() => {
    if (jurilistResult.isSuccess) {
      setJuriOptions(jurilistResult.data?.data);
    }
    if (jurilistResult.isError) {
      setValues({ ...values, jurisdiction_id: "" });
      setGetError(
        jurilistResult.error?.data?.message ||
        jurilistResult.error.error ||
        jurilistResult.error.data?.msg.sqlMessage
      );
      setJuriOptions([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: jurilistResult.isLoading });
  }, [jurilistResult.isLoading]);

  const inputs = [
    {
      id: 1,
      name: "gf_number",
      type: "text",
      value: values?.gf_number || "",
      placeholder: "Enter GF number",
      errorMessage: "Please enter GF number to proceed further!",
      label: "GF no.",
      column: "3",
      required: true,
    },
    {
      id: 22,
      name: "state_id",
      type: "select",
      value: values?.state_id || "",
      options: stateList,
      errorMessage: "Please select state!",
      label: "State",
      required: true,
      column: "3",
    },
    {
      id: 2,
      name: "county_id",
      type: "select",
      value: values?.county_id || "",
      options: CountyOptions,
      placeholder: "Select County",
      errorMessage: "Please select county!",
      label: "County",
      required: true,
      column: "3",
    },

    {
      id: 3,
      name: "jurisdiction_id",
      type: "select",
      value: values?.jurisdiction_id || "",
      column: "3",
      options: juriOptions,
      errorMessage: "Please select jurisdiction!",
      label: "Jurisdiction",
      required: true,
    },

    {
      id: 4,
      name: "seller_1",
      value: values?.seller_1 || "",
      type: "textArea",
      column: "3",
      label: "Seller 1",
    },
    {
      id: 5,
      name: "seller_2",
      value: values?.seller_2 || "",
      type: "textArea",
      column: "3",
      label: "Seller 2",
    },
    {
      id: 4,
      name: "seller_3",
      type: "textArea",
      value: values?.seller_3 || "",
      column: "3",
      label: "Seller 3",
    },
    {
      id: 7,
      name: "seller_4",
      type: "textArea",
      value: values?.seller_4 || "",
      column: "3",
      label: "Seller 4",
    },
    {
      id: 8,
      name: "buyer_1",
      type: "textArea",
      value: values?.buyer_1 || "",
      column: "3",
      label: "Buyer 1",
    },
    {
      id: 9,
      name: "buyer_2",
      type: "textArea",
      value: values?.buyer_2 || "",
      column: "3",
      label: "Buyer 2",
    },
    {
      id: 10,
      name: "buyer_3",
      value: values?.buyer_3 || "",
      type: "textArea",
      column: "3",
      label: "Buyer 3",
    },
    {
      id: 11,
      name: "buyer_4",
      type: "textArea",
      value: values?.buyer_4 || "",
      column: "3",
      label: "Buyer 4",
    },
    {
      id: 12,
      name: "legal_description",
      type: "textArea",
      value: values?.legal_description || "",
      column: "3",
      label: "Legal Description",
    },
  ];

  const handleReset = (e) => {
    e.preventDefault();
    setValues("");
    clearStatus("");
  };
  const onChange = (e) => {
    clearStatus("");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.replace(/[^a-zA-Z" "]/gi, ""),
    });
    if (name === "gf_number") {
      setValues({ ...values, [name]: value });
    }
  };
  const selectHandler = (property, value, data_name) => {
    setValues({ ...values, [property]: value });
    clearStatus("");
    //if there is data_name attribute
    if (data_name) {
      if (property === "state_id") {
        setValues({
          ...values,
          state_name: data_name.getAttribute("data_name"),
          state_id: value,
        });
        setCountyOptions([]);
        setJuriOptions([]);
        //send reqest for county
        if (value) {
          getCountyList({ state_id: { state_id: value }, token });
        } else {
          setValues({ ...values, [property]: value, county_id: "" });
        }
      } else if (property === "county_id") {
        setValues({
          ...values,
          county_name: data_name.getAttribute("data_name"),
          county_id: value,
        });
        if (value) {
          //send reqest for jurilist
          jurilist({ id: value, token });
        } else {
          setJuriOptions([]);
          setValues({ ...values, [property]: value, jurisdiction_id: "" });

        }
      } else if (property === "jurisdiction_id") {
        //juri info
        mutateJuriBond({ id: value, token })
        setValues({
          ...values,
          jurisdiction_name: data_name.getAttribute("data_name"),
          jurisdiction_id: value,
        });
      }
    }
  };

  const onChangeHandler = (e) => {
    clearStatus("");
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const exportPDFWithMethod = () => {
    let element = current.current;
    if (element !== null) {
      drawDOM(element, {
        forcePageBreak: ".page-break",
        paperSize: "A4",
        keepTogether: ".keep-together",
        fileName: "cert-simple.pdf",
        margin: {
          top: "0.7cm",
          left: "1.5cm",
          right: "1.5cm",
          bottom: "0.7cm",
        },
      })
        .then((group) => {
          return exportPDF(group);
        })
        .then((dataUri) => {
          const { state_name, county_name, jurisdiction_name, ...notice } =
            values;

          addnotice({
            data: {
              ...notice,
              documents: `data:application/pdf;base64,${dataUri.split(";base64,")[1]
                }`,
              user_id: user?.user_id,
            },
            token,
          });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    exportPDFWithMethod();
  };

  return (
    <>
      <div className="position-relative">
        {loading && <AppSpinner />}
        <h5>Notice to Purchaser</h5>
        <Row>
          <form onSubmit={handleSubmit}>
            {getError && (
              <div className="my-2">
                <Alert message={getError || message} type="error" showIcon />{" "}
              </div>
            )}
            <Card>
              <Card.Body>
                <Row>
                  {inputs.map((input) => (
                    <>
                      {/* //appSelcet for county  */}
                      {input.type === "select" &&
                        input.name === "county_id" && (
                          <AppSelect
                            key={input.id}
                            {...input}
                            onChange={selectHandler}
                            options={
                              <>
                                <option value={""}>Select County</option>
                                {input.options.map((ele) => {
                                  return (
                                    <option
                                      data_name={ele.county_name}
                                      key={ele.id}
                                      value={ele.id}
                                    >
                                      {ele.county_name}
                                    </option>
                                  );
                                })}
                              </>
                            }
                          />
                        )}

                      {input.type === "select" && input.name === "state_id" && (
                        <AppSelect
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input.options.map((ele) => {
                                return (
                                  <option
                                    data_name={ele.state_name}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                      {input.type === "select" &&
                        input.name === "jurisdiction_id" && (
                          <AppSelect
                            key={input.id}
                            {...input}
                            onChange={selectHandler}
                            options={
                              <>
                                <option value={""}>Select Jurisdiction</option>
                                {input.options.map((ele) => {
                                  return (
                                    <option
                                      data_name={ele.display_name}
                                      key={ele.id}
                                      value={ele.id}
                                    >
                                      {ele.display_name}
                                    </option>
                                  );
                                })}
                              </>
                            }
                          />
                        )}

                      {input.type === "text" && (
                        <FormInput
                          key={input.id}
                          {...input}
                          defaultSet={""}
                          //  value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    </>
                  ))}
                </Row>
              </Card.Body>
            </Card>
            <div className="row">
              {inputs.map((input) => (
                <>
                  {input.type === "textArea" && (
                    <AppTextArea
                      key={input.id}
                      onChange={onChangeHandler}
                      {...input}
                    />
                  )}
                </>
              ))}
            </div>
            <Row>
              <Col className="my-3">
                <div className="d-flex justify-content-center">
                  <div className="me-md-2 ">
                    <AppButton
                      disabled="true"
                      onClick={handleReset}
                      title={"CLEAR"}
                      type={"button"}
                      variant={"secondary-md"}
                    />
                  </div>
                  <div>
                    <AppButton
                      disabled="true"
                      type={"submit"}
                      title={"DOWNLOAD"}
                      variant={"primary-md"}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Row>
      </div>
      <div
        style={{
          position: "absolute",
          left: "-1500px",
          top: 0,
        }}
      >
        <div
          style={{
            width: "520px",
          }}
          ref={current}
        >
          <NewNotice values={values} />
        </div>
      </div>
    </>
  );
}
